<template lang="pug">
a-layout-sider(
  :reverseArrow="reverseArrow"
  v-model="collapsed"
  :collapsible="collapsible"
  :collapsedWidth="collapsedWidth"
  :width="width"
  :theme="theme"
  :class="className"
  :style="customStyle")
  slot
</template>
<script>
export default {
  name: "LayoutSider",
  props: {
    collapsedWidth: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 250
    },
    theme: {
      type: String,
      default: null
    },
    reverseArrow: {
      type: Boolean,
      default: false
    },
    collapsible: {
      type: Boolean,
      default: true
    },
    customStyle: {
      type: Object,
      default: () => {}
    },
    className: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      collapsed: false
    };
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../less/main.less";
.sider-main {
  background: var(--container-color) !important;
  height: 100vh !important;
}

.sider-panel {
  display: flex;
  width: 100%;
  background: var(--gray-color) !important;
}

.sider-dashboard {
  height: 100vh !important;
  background: var(--white-color) !important;
}
</style>
